import React from 'react';
import '../Styles/HeaderComponent.css'; 
import logo from '../Img/logo.svg';
import dexscreener from '../Img/dexscreener.svg';
import tgIcon from '../Img/warp.png';
const HeaderComponent = ({ onMenuClick, landingRef, mechanismRef, tokenomicsRef, roadmapRef }) => {
  return (
    <header className="header">
      <div className="header-logo" onClick={() => onMenuClick(landingRef)}>
        <img src={logo} alt="Logo" />
      </div>
      <nav className="header-nav">
        <a className="nav-link" onClick={() => onMenuClick(mechanismRef)}>About</a>
        <a className="nav-link" onClick={() => onMenuClick(tokenomicsRef)}>Tokenomics</a>
      </nav>
      <div className="header-social">
      <a href="https://dexscreener.com/base/0x48fea30be992368ebda0309023020784a5623463" target="_blank" rel="noopener noreferrer">
          <img src={dexscreener} alt="Dextools" />
        </a>
        <a href="https://warpcast.com/lostonbase/0xd8ec185c" target="_blank" rel="noopener noreferrer">
          <img src={tgIcon} alt="Telegram" />
        </a>
      </div>
    </header>
  );
};

export default HeaderComponent;
