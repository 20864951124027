import React from 'react';
import '../Styles/Footer.css';
import dexscreener from '../Img/dexscreener.svg';
import tgIcon from '../Img/warp.png';

const Footer = () => {
  return (
    <footer className="footer">
      <p>$LOST on Base, All rights reserved ©</p>
      <div className="footer-icons">
      <a href="https://dexscreener.com/base/0x48fea30be992368ebda0309023020784a5623463" target="_blank" rel="noopener noreferrer">
          <img src={dexscreener} alt="Dextools" />
        </a>
        <a href="https://warpcast.com/lostonbase/0xd8ec185c" target="_blank" rel="noopener noreferrer">
          <img src={tgIcon} alt="Telegram" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
