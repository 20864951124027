import React, { useEffect, useRef } from 'react';
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import HeaderComponent from './Components/Code/HeaderComponent';
import LandingComponent from './Components/Code/LandingComponent';
import Mechanism from './Components/Code/Mechanism';
import Tokenomics from './Components/Code/Tokenomics';
import Footer from './Components/Code/Footer';

function App() {
  const landingRef = useRef(null);
  const mechanismRef = useRef(null);
  const tokenomicsRef = useRef(null);
  const roadmapRef = useRef(null);

  useEffect(() => {
    AOS.init({ duration: 400 });
  }, []);

  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: 'smooth'
      });
    }
  };
  return (
    <div className="App">
      <HeaderComponent 
        onMenuClick={scrollToSection} 
        landingRef={landingRef} 
        mechanismRef={mechanismRef} 
        tokenomicsRef={tokenomicsRef} 
      />
      <div ref={landingRef}><LandingComponent /></div>
      <div ref={mechanismRef}><Mechanism /></div>      
      <div ref={tokenomicsRef}><Tokenomics /></div>
      <Footer />
    </div>
  );
}

export default App;