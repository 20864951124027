import React from 'react';
import coins from '../Img/coin.png';
import lost from '../Img/lost.svg';
import '../Styles/Tokenomics.css';

const Tokenomics = () => {
  return (
    <div className="tokenomics">
      <h2 data-aos="fade-up" data-aos-duration="1000">Tokenomics</h2>
      <div className="tokenomics-content">
        <img src={coins} alt="" className="coins" />
        <img src={lost} alt="" className="lost" />
      </div>
      <div className="tokenomics-info">
        <div className="info-box">0/0 TAX</div>
        <div className="info-box">100.000.000 SUPPLY</div>
        <div className="info-box">BASE</div>
      </div>
      <div className="contract-address">
        <p>CONTRACT ADDRESS</p>
        <p>0xdD68fFBbe1f3d9EdC41F9EE9d73e7cF6d660bCd9</p>
      </div>
    </div>
  );
};

export default Tokenomics;
