import React from 'react';
import '../Styles/Mechanism.css'; 
import img1 from '../Img/img1.svg';
import img2 from '../Img/img2.svg';
import img3 from '../Img/img3.svg';


const Mechanism = () => {
  return (
    <div className="mechanism">
      <h2 data-aos="fade-up" data-aos-duration="1000">Where tf did I land????</h2>
      <div className="mechanism-container">
        <div className="mechanism-item" data-aos="fade-right" data-aos-duration="1000">
          <img src={img1} alt="" className='shake'/>
          <p className="mechanismp">My fucking plane crashed. I have no idea where I am. What the hell is this island???</p>
        </div>
        <div className="mechanism-item" data-aos="fade-up" data-aos-duration="1000">
          <img src={img2} alt="" className='shakeZoom' />
          <p className="mechanismp">I need to come back home. Somebody help me asap I'm going crazy</p>
        </div>
        <div className="mechanism-item" data-aos="fade-left" data-aos-duration="1000">
          <img src={img3} alt="" className='shake'/>
          <p className="mechanismp">Why me? What is this place? What do they mean by "chosen one"?</p>
        </div>
      </div>
    </div>
  );
};

export default Mechanism;
