import React from 'react';
import '../Styles/LandingComponent.css'; 
import banner from '../Img/banner.svg';
const LandingComponent = () => {
  const handleBuyClick = () => {
    console.log("hop");
  };

  return (
    <div className="landing-container">
      <div className="banner-image-container">
        <img src={banner} alt="Banner" className="banner-image"/>
      </div>
      <div className="text-button-container">
        <h1 data-aos="fade-up" data-aos-duration="1000">Oh fuck, I'm $LOST</h1>
        <p>PLEASE DO NOT INTERACT WITH ANY TELEGRAM/TWITTER LINK UNTIL WE UPDATE THIS WEBSITE. WE HAVE BEEN HACKED, WARPCAST IS THE ONLY SOCIAL MEDIA WHERE WE STILL ARE ACTIVE FOR NOW, UNTIL WE FIGURE IT OUT.</p>
        <p>On a desert island, a lost soul is stranded without a clue as to how he got there. Disoriented and $LOST, he discovers mysterious symbols carved into the rocks. Convinced that his destiny is linked to this place, he decides to decipher the symbols to understand his presence here. Gradually, he realizes that the symbols reveal clues to his own past and life choices. His mission then becomes clear: to discover why he is here and what this island has to teach him about himself.</p>
        <button 
          className="enter-dapp-btn" 
          data-aos="fade-up" 
          data-aos-duration="1000" 
          onClick={handleBuyClick}
        >
          Buy $LOST
        </button>
      </div>
    </div>
  );
};

export default LandingComponent;
